<template>
  <b-container>
    <b-card>
      <router-link :to="{name:'play/season-1/level-1'}">Level 1</router-link>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: "Levels"
}
</script>

<style scoped>

</style>